<template>
  <div class='news'>
      <div class="title">{{$t('news.title1')}}</div>
      <div class="news-section">
          <div class="content">
              <div class="sort" data-aos="fade-up">
                  <div class="sort-item" @click="getNewsList([0])">全部</div>
                  <div class="sort-item" v-for="item in newsSort" :key="item.id" @click="getNewsList([item.id])">{{item.name}}</div>
              </div>
          </div>
          <div class="content">
              <div class="news-item" v-for="(item,index) in newsList" :key="index">
                  <div class="news-type" data-aos="fade-up">{{item.categoryName}}</div>
                  <div class="news-content">
                      <!-- 每一条新闻 -->
                      <div class="news-detail" data-aos="fade-up" 
                      @click="toNewsDetail(item2.newsId,item2.title)"
                      v-for="(item2,index2) in item.news" :key="index2">
                          <div class="news-date">> {{$t('news.date')}}：{{item2.createTime.replace('T','  ')}}</div>
                          <div class="news-text">
                              <div class="text-left">
                                  <h4>{{item2.title}}</h4>
                                  <p>{{item2.description}}</p>
                              </div>
                              <div class="text-right">
                                  <img :src="item2.imgPath" alt=""/>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="more">
                      <span @click="getMore(item.categoryName,item.index)">{{item.more}}</span>
                  </div>
              </div>
          </div>
      </div>
      <back-top></back-top>
  </div>
</template>

<script>
import BackTop from "../../components/content/backTop/BackTop.vue";
import {getNews,getNewsList,getMoreNews} from '../../network/news'
export default {
  name:'News',
  data () {
    return {
        //新闻分类
        newsSort:[],
        newsList:[],
    };
  },
  created(){
      this.getNews();
  },
  methods: {
      //获取新闻分类
      getNews(){
          getNews().then((res)=>{
            // console.log(res);
              if(res.status !=="ok"){
                  return this.$message.error(this.$t('news.tip'));
              }
              res.data.forEach(item => {
                  item.index=2;
              });    
              this.newsSort=res.data
              this.getNewsList([0]);
          })
      },
      //获取全部新闻
      getNewsList(arr){
          getNewsList(arr).then(res=>{
            //   console.log(res);
              if(res.status !=="ok"){
                  return this.$message.error(this.$t('news.tip2'));
              }
              res.data.forEach(item=>{
                  if(!item.isMore){
                      item.more=""
                  }else{
                      item.more="More"
                  }
              })
              this.newsList=res.data
            //   console.log(this.newsList);
          })
      },
      //获取更多
      getMore(categoryName,index){

          //cateid
          let id=null;
          let pageIndex=0;
          this.newsSort.forEach(item=>{
              if(item.name!==categoryName){
                  return false;
              }else{
                  pageIndex=item.index++;
                  id=item.id;
              }
          })
        //   console.log(pageIndex);
          getMoreNews(id,pageIndex).then(res=>{
              if(res.status !=="ok"){
                  return this.$message.error(this.$t('news.tip3'));
              }
              this.newsList.forEach(item=>{
                if(item.categoryName!==categoryName){
                    return false;
                }else{
                    if(res.data.length<7){
                        item.more=""
                    }
                    res.data.forEach(item2 => {
                        item.news.push(item2)
                    });
                }
              })
          })
        //   console.log(this.newsList);
        //   console.log(this.newsSort);
      },
      //跳转新闻详情
      toNewsDetail(id,title){
          this.$router.push({name:'NewsDetail',params:{title:title,nid:id}})
      }
  },

  components: {
      BackTop
  },

  computed: {
      saveY(){
          return document.documentElement.scrollTop || document.scrollTop
      }
  },

}

</script>
<style lang='less' scoped>
@import '../../assets/css/news.less';
</style>