import {request} from './request'

//获取新闻分类
export function getNews(){
    return request({
        url:"/api/News/GetCategory"
    })
}
//获取所有新闻
export function getNewsList(arr){
    return request({
        method:"POST",
        url:"/api/News/NewsList",
        data:arr
    })
}
//获取新闻详情
export function getNewsDetail(newsid){
    return request({
        url:"/api/News/GetNewsDetail",
        params:{
            id:newsid
        }
    })
}

export function getMoreNews(categoryId,pageIndex){
    return request({
        url:"/api/News/NewsListMore",
        method:"POST",
        params:{
            categoryId,
            pageIndex
        }
    })
}